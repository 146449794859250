import React, { ReactNode } from "react";
import * as DropdownMenuPrimtiive from "@radix-ui/react-dropdown-menu";

type DropdownMenuRootProps = {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  children: ReactNode;
};

export const DropdownMenuRoot = ({ children, ...props }: DropdownMenuRootProps) => {
  return <DropdownMenuPrimtiive.Root {...props}>{children}</DropdownMenuPrimtiive.Root>;
};
