import { Duration } from "dayjs/plugin/duration";
import {
  Branch,
  InterventionDeliveryMethod,
  InterventionStep,
  InterventionStepBranching,
} from "./interventions";
import { Psychoed } from "./psychoed";

export type ID = string | number;

export type ProtocolItem = {
  id: string;
  name: string;
  description: string;
  status: Status;
};

export enum Status {
  draft = "draft",
  published = "published",
}

type Pagination<T> = {
  cursor: string;
  data: T[];
};

export interface ISOInterval {
  iso8601Duration: Duration;
  direction: "forward" | "backward";
}

interface ISOIntervalForward extends ISOInterval {
  iso8601Duration: Duration;
  direction: "forward";
}

export type CourseItem = {
  id: string;
  slug: string;
  name: string;
  description: string;
  sessions: Pagination<SessionItem>;
  status: Status;
};

type CourseSessionTime = {
  relativeDate: ISOInterval;
  timeOfDay: string;
};

export type SessionItem = {
  id: string;
  name: string;
  description: string;
  time: CourseSessionTime;
  status: Status;
  interventions: CourseInterventionInstance[];
  reminders: ReminderItem[];
};

export type ReminderItem = {
  id: string;
  text: string;
  timeFromSession: ISOInterval;
};

export type ProtocolInterventionInstance = {
  id: string;
  position: number;
  interventionInstance: ProtocolIntervention;
};

export type ProtocolIntervention = ProtocolCustomIntervention | ProtocolPsychoed;

type ProtocolCustomIntervention = {
  __typename: "ProtocolCustomIntervention";
  customIntervention: CustomInterventionItem;
  time: ISOInterval;
  recurrence: "once" | "always";
};

type ProtocolPsychoed = {
  __typename: "ProtocolPsychoed";
  psychoed: Psychoed;
  time: ISOInterval;
};

export type CourseInterventionInstance = {
  id: string;
  position: number;
  interventionInstance: CourseIntervention;
};

type CourseIntervention =
  | CourseCustomIntervention
  | CourseCopingStrategy
  | CourseBehaviouralAction
  | CourseQuestionaire
  | CourseThoughtRecord
  | CoursePsychoed;

type CourseCustomIntervention = {
  __typename: "CourseCustomIntervention";
  customIntervention: CustomInterventionItem;
  timeFromSession: ISOInterval;
  recurrence: "once" | "always";
};

type CoursePsychoed = {
  __typename: "CoursePsychoed";
  psychoed: Psychoed;
  timeFromSession: ISOInterval;
};

// TODO: implement built-in interventions
type CourseCopingStrategy = never;
type CourseBehaviouralAction = never;
type CourseQuestionaire = never;
type CourseThoughtRecord = never;

export type CustomInterventionItem = {
  id: string;
  slug?: string;
  name: string;
  description: string;
  authorId?: string;
  copiedFrom?: CustomInterventionItem | string;
  draft?: boolean;
  visibility?: "private" | "clinic" | "global";
  deliveryMethod: InterventionDeliveryMethod;
  steps?: InterventionStep[]; // deprecated
  initialStep?: InterventionStepBranching;
  stepsBranching: InterventionStepBranching[];
  archived: boolean;
  metrics?: {
    name: string;
    maxValue: number;
  }[];
  draftVersion?: {
    id: string;
  };
  originalVersion?: {
    id: string;
  };
};

export type CustomBranchingInterventionItem = Omit<
  CustomInterventionItem & {
    branches: Branch[];
  },
  "stepsBranching"
>;

enum MessageAuthor {
  limbic,
  user,
}

export enum StepType {
  informational = "informational",
  freeText = "free_text",
  singleChoice = "single_choice",
  weightedSingleChoice = "weighted_single_choice",
  multipleChoice = "multiple_choice",
  emotion = "emotion",
  thought = "thought",
  activity = "activity",
  media = "media",
  slider = "slider", // Doesn't seem to exist??
}
