import * as SelectPrimitive from "@radix-ui/react-select";
import clsx from "clsx";
import React from "react";

type SelectContentProps = {
  children: React.ReactNode | React.ReactNode[];
  small?: boolean;
};

export const SelectContent = ({ children, small }: SelectContentProps) => {
  return (
    <SelectPrimitive.Content
      className={
        "flex min-w-[6rem] animate-intro-fade flex-col rounded-md border-black bg-white z-10"
      }>
      <div
        className={clsx(
          "overflow-auto border-[1px] border-gray-300",
          small ? "rounded-2xl" : "rounded-md",
        )}>
        {children}
      </div>
    </SelectPrimitive.Content>
  );
};
