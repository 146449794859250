import React from "react";
import * as SelectPrimitive from "@radix-ui/react-select";

type SelectViewportProps = {
  children: React.ReactNode | React.ReactNode[];
};

export const SelectViewport = ({ children }: SelectViewportProps) => {
  return <SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>;
};
