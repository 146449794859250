import React from "react";
import * as SelectPrimitive from "@radix-ui/react-select";

type SelectItemTextProps = {
  children: React.ReactNode | React.ReactNode[];
};

export const SelectItemText = ({ children }: SelectItemTextProps) => {
  return <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>;
};
