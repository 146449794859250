import React from "react";
import * as SelectPrimitive from "@radix-ui/react-select";

type SelectIconProps = {
  children: React.ReactNode;
};

export const SelectIcon = ({ children }: SelectIconProps) => {
  return <SelectPrimitive.Icon>{children}</SelectPrimitive.Icon>;
};
