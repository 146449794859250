import { DropdownMenuContent } from "./DropdownMenuContent";
import { DropdownMenuItem } from "./DropdownMenuItem";
import { DropdownMenuRoot } from "./DropdownMenuRoot";
import { DropdownMenuTrigger } from "./DropdownMenuTrigger";

export const DropdownMenu = {
  Content: DropdownMenuContent,
  Item: DropdownMenuItem,
  DropdownMenu: DropdownMenuRoot,
  Trigger: DropdownMenuTrigger,
};
