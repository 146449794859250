import * as SelectPrimitive from "@radix-ui/react-select";
import clsx from "clsx";

type SelectValueProps = {
  children: React.ReactNode;
  className?: string;
};

export const SelectValue = ({ children, className }: SelectValueProps) => {
  return <SelectPrimitive.Value className={clsx(className)}>{children}</SelectPrimitive.Value>;
};
