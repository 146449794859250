import React from "react";
import * as SelectPrimitive from "@radix-ui/react-select";

type SelectRootProps<T> = {
  value: T;
  onValueChange: (value: T) => void;
  defaultValue?: T;
  disabled?: boolean;
  children: React.ReactNode | React.ReactNode[];
};

export function SelectRoot<T extends string = string>({
  value,
  onValueChange,
  defaultValue,
  children,
  disabled,
}: SelectRootProps<T>) {
  return (
    <SelectPrimitive.Root
      defaultValue={defaultValue}
      disabled={disabled}
      value={value}
      onValueChange={onValueChange}>
      {children}
    </SelectPrimitive.Root>
  );
}
