import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import clsx from "clsx";
import React from "react";

type DropdownMenuContentProps = {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
};

export const DropdownMenuContent = ({ children, className }: DropdownMenuContentProps) => {
  return (
    <DropdownMenuPrimitive.Content align="end" asChild>
      <div
        className={clsx(
          "flex min-w-[6rem] animate-intro-fade flex-col rounded-md border-[1px] border-gray-300 bg-white p-1",
          className,
        )}>
        {children}
      </div>
    </DropdownMenuPrimitive.Content>
  );
};
