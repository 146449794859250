import React, { forwardRef } from "react";

type IconButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
};

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, onClick, ...props }: IconButtonProps, forwardedRef) => {
    return (
      <button
        onClick={onClick}
        className={
          "flex h-7 w-7 flex-shrink-0 cursor-pointer items-center justify-center rounded-md bg-transparent p-1 text-gray-600 transition duration-standard ease-in-out hover:bg-gray-100 hover:text-gray-700"
        }
        ref={forwardedRef}
        {...props}>
        {children}
      </button>
    );
  },
);
