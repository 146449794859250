import { Navbar } from "../../components/rough/Navbar";

export const Page: React.FC = ({ children }) => {
  return (
    <div className="relative flex h-full w-full flex-1 shrink-0 flex-col overflow-auto bg-gray-50">
      <Navbar />
      {children}
    </div>
  );
};
