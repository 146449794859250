import * as SelectPrimitive from "@radix-ui/react-select";
import clsx from "clsx";
import React from "react";

type SelectItemProps = {
  value: string;
  children: React.ReactNode | React.ReactNode[];
  small?: boolean;
};

export const SelectItem = ({ value, children, small }: SelectItemProps) => {
  return (
    <SelectPrimitive.Item value={value} asChild>
      <div
        style={{ outlineWidth: "0" }}
        className={clsx(
          "bg-white font-sans leading-none text-gray-600 focus:bg-gray-100 focus:text-gray-700",
          small && "text-sm",
          small ? "h-7" : "h-9",
          "relative flex cursor-pointer select-none items-center rounded px-2 transition-all duration-200",
        )}>
        {children}
      </div>
    </SelectPrimitive.Item>
  );
};
