import clsx from "clsx";
import React from "react";

type TextareaProps = {
  id?: string;
  key?: string;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  onValueChange: React.EventHandler<React.ChangeEvent<HTMLTextAreaElement>>;
  disabled?: boolean;
};

export const Textarea = ({
  placeholder,
  value,
  defaultValue,
  onValueChange,
  disabled,
}: TextareaProps) => {
  return (
    <textarea
      defaultValue={defaultValue}
      value={value}
      onChange={onValueChange}
      placeholder={placeholder}
      className={clsx(
        "min-h-[5rem] w-full rounded-md border-[1px] border-gray-300 bg-gray-50 p-3 leading-normal text-gray-600 transition-all duration-200 ease-in-out focus:border-gray-400 focus:bg-white focus:text-gray-700",
        "outline-none",
      )}
      style={{ resize: "none" }}
      disabled={disabled}
    />
  );
};
