import Image from "next/image";

type LogoProps = {
  width?: number;
  height?: number;
};

export const Logo = ({ width = 104, height = 24 }: LogoProps) => {
  return <Image alt={"Limbic Logo"} src="/images/logo.svg" width={width} height={height} />;
};
