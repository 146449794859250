import clsx from "clsx";
import React, { forwardRef } from "react";

type TextInputProps = {
  id: string;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  onValueChange: React.EventHandler<React.ChangeEvent<HTMLInputElement>>;
  onBlur?: () => void;
  isInvalid?: boolean;
  autoComplete?: string;
  className?: string;
  parentClassName?: string;
  icon?: React.ReactNode;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const getBaseTextInputClasses = ({
  isInvalid,
  icon,
}: {
  isInvalid?: boolean;
  icon?: boolean;
}) =>
  clsx(
    "w-full rounded-md leading-5 px-2",
    "text-gray-700 h-9 focus:text-gray-900",
    isInvalid
      ? "border-red-300 focus:border-red-400 border-2"
      : "border-gray-300 focus:border-gray-400 border-[1px]",
    "bg-gray-50 focus:bg-white",
    "transition-all duration-standard ease-in-out",
    "outline-none",
    icon ? "pl-10" : "",
  );

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      value,
      placeholder,
      onValueChange,
      onBlur,
      isInvalid,
      className,
      parentClassName,
      icon,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <span className={clsx("relative", parentClassName)}>
        {icon}

        <input
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={onValueChange}
          onBlur={() => onBlur?.()}
          className={clsx(getBaseTextInputClasses({ isInvalid, icon: !!icon }), className)}
          ref={forwardedRef}
          {...props}
        />
      </span>
    );
  },
);
