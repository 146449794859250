import * as LabelPrimitive from "@radix-ui/react-label";
import clsx from "clsx";
import React from "react";

type LabelProps = {
  htmlFor: string;
  children: React.ReactNode | React.ReactNode[];
  className?: string;
} & React.LabelHTMLAttributes<HTMLLabelElement>;

export const Label = ({ htmlFor, children, className, ...props }: LabelProps) => (
  <LabelPrimitive.Root htmlFor={htmlFor} asChild>
    <label {...props} className={clsx("w-full select-none text-gray-800", className)}>
      {children}
    </label>
  </LabelPrimitive.Root>
);
