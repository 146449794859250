import React from "react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";

type DropdownMenuTriggerProps = {
  children: React.ReactNode;
};

export const DropdownMenuTrigger = ({ children }: DropdownMenuTriggerProps) => {
  return <DropdownMenuPrimitive.Trigger asChild>{children}</DropdownMenuPrimitive.Trigger>;
};
