import React from "react";

type SpinnerIconProps = {
  color?: string;
  size?: number | string;
};

export const SpinnerIcon = ({ color = "currentColor", size = 24 }: SpinnerIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 12C19 12.9193 18.8189 13.8295 18.4672 14.6788C18.1154 15.5281 17.5998 16.2997 16.9497 16.9497C16.2997 17.5998 15.5281 18.1154 14.6788 18.4672C13.8295 18.8189 12.9193 19 12 19C11.0807 19 10.1705 18.8189 9.32121 18.4672C8.47194 18.1154 7.70026 17.5998 7.05025 16.9497C6.40024 16.2997 5.88463 15.5281 5.53284 14.6788C5.18106 13.8295 5 12.9193 5 12C5 11.0807 5.18106 10.1705 5.53284 9.32121C5.88463 8.47193 6.40024 7.70026 7.05025 7.05025C7.70026 6.40024 8.47194 5.88462 9.32122 5.53284C10.1705 5.18106 11.0808 5 12 5C12.9193 5 13.8295 5.18106 14.6788 5.53284C15.5281 5.88463 16.2997 6.40024 16.9498 7.05026C17.5998 7.70027 18.1154 8.47194 18.4672 9.32122C18.8189 10.1705 19 11.0808 19 12L19 12Z"
        stroke="currentColor"
        strokeWidth="2"
        opacity="0.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 5C13.2291 5 14.4365 5.32362 15.5009 5.93832C16.5652 6.55302 17.4489 7.43712 18.0632 8.50172C18.6774 9.56633 19.0005 10.7739 19 12.003C18.9995 13.2321 18.6753 14.4394 18.0602 15.5034C17.445 16.5675 16.5606 17.4509 15.4957 18.0647C14.4308 18.6785 13.2231 19.001 11.994 19C10.7649 18.9989 9.55778 18.6743 8.49397 18.0587C7.43015 17.4431 6.54718 16.5582 5.93384 15.4931"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
