import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import clsx from "clsx";
import React, { forwardRef } from "react";

type DropdownMenuItemProps = {
  onSelect: (event: Event) => void;
  children: React.ReactNode;
  small?: boolean;
  textClassName?: string;
};

export const DropdownMenuItem = forwardRef<HTMLDivElement, DropdownMenuItemProps>(
  (
    { onSelect, children, small = true, textClassName, ...props }: DropdownMenuItemProps,
    forwardedRef,
  ) => {
    return (
      <DropdownMenuPrimitive.DropdownMenuItem
        onSelect={(e) => {
          e.stopPropagation();
          onSelect(e);
        }}
        onClick={(e) => e.stopPropagation()}
        ref={forwardedRef}
        asChild
        {...props}>
        <div
          className={clsx(
            "relative flex h-7 cursor-pointer select-none items-center rounded-md bg-white p-1.5 font-sans leading-tight text-gray-600 transition-all duration-standard ease-in-out focus:bg-gray-100 focus:text-gray-700",
            small ? "text-sm" : "text-base",
            textClassName,
          )}
          // Refactor into reset, get boxShadows up in system
          style={{ outlineWidth: "0" }}>
          <span>{children}</span>
        </div>
      </DropdownMenuPrimitive.DropdownMenuItem>
    );
  },
);
