import { SelectContent } from "./SelectContent";
import { SelectIcon } from "./SelectIcon";
import { SelectItem } from "./SelectItem";
import { SelectItemText } from "./SelectItemText";
import { SelectRoot } from "./SelectRoot";
import { SelectTrigger } from "./SelectTrigger";
import { SelectValue } from "./SelectValue";
import { SelectViewport } from "./SelectViewport";

export const Select = {
  Content: SelectContent,
  Icon: SelectIcon,
  Item: SelectItem,
  ItemText: SelectItemText,
  Select: SelectRoot,
  Trigger: SelectTrigger,
  Value: SelectValue,
  Viewport: SelectViewport,
};
