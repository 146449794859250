import clsx from "clsx";
import React from "react";
import { SpinnerIcon } from "../Icons/SpinnerIcon";

export const Spinner = ({ className, size }: { className?: string; size?: number }) => {
  return (
    <div className={clsx("flex animate-spin items-center justify-center text-gray-800", className)}>
      <SpinnerIcon size={size} />
    </div>
  );
};
