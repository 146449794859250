import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";

type NavbarLinkProps = {
  href: string;
  children: string;
};

export const NavbarLink = ({ href, children }: NavbarLinkProps) => {
  const router = useRouter();
  const isActive = router.pathname.startsWith(href);

  return (
    <Link href={href} passHref legacyBehavior>
      <a
        className={clsx(
          "inline-flex cursor-pointer items-center rounded-md px-2 py-1 font-medium transition-all duration-200 ease-in-out",
          "hover:bg-gray-200 hover:text-gray-700 focus:bg-gray-200 focus:text-gray-700",
          isActive ? "bg-gray-200/75 text-gray-700" : "bg-transparent text-gray-600",
        )}>
        {children}
      </a>
    </Link>
  );
};
