import * as SelectPrimitive from "@radix-ui/react-select";
import clsx from "clsx";
import React from "react";

type SelectTriggerProps = {
  ariaLabel: string;
  children: React.ReactNode;
  small?: boolean;
  className?: string;
};

export const SelectTrigger = ({ ariaLabel, children, small, className }: SelectTriggerProps) => {
  return (
    <SelectPrimitive.Trigger aria-label={ariaLabel} asChild>
      <button
        className={clsx(
          small ? "h-7 rounded-2xl text-sm" : "h-9 rounded-md",
          "relative inline-flex w-full cursor-pointer items-center justify-between border-[1px] bg-gray-50 leading-none text-gray-600 outline-none transition-all duration-200 ease-in-out hover:bg-white focus:bg-white focus:text-gray-700",
          className,
        )}>
        <div className={"inline-flex w-full items-center justify-between px-2"}>{children}</div>
      </button>
    </SelectPrimitive.Trigger>
  );
};
