import React, { forwardRef } from "react";
import { getBaseTextInputClasses } from "./TextInput";

type PasswordInputProps = {
  id: string;
  value: string;
  placeholder?: string;
  onValueChange: React.EventHandler<React.ChangeEvent<HTMLInputElement>>;
  onBlur?: () => void;
  isInvalid?: boolean;
  autoComplete?: string;
};

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ value, placeholder, onValueChange, onBlur, isInvalid, ...props }: PasswordInputProps) => {
    return (
      <input
        type="password"
        value={value}
        placeholder={placeholder}
        onChange={onValueChange}
        onBlur={() => onBlur?.()}
        className={getBaseTextInputClasses({ isInvalid })}
        {...props}
      />
    );
  },
);
